.map{
	position: absolute;
	top: 40px;
	right: 40px;
	width: 200px;
	height: 200px;
	opacity: 0.8;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		top: 15px;
		right: 15px;
		width: 120px;
		height: 120px;
	}
	svg{
		width: 100%;
		height: 100%;
		z-index: 1;
		position: relative;
		.goalroom{
			circle{
				opacity: 0;
			}
			text{
				transition: fill 0.5s ease-out;
			}
			&.blink{
				circle{
					animation: blinkAnim 1.5s linear infinite;
				}
				text{
					fill: #FFEDB3;
				}
			}
		}
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($black, 0.1);
		box-shadow: 0 0 20px 20px rgba($black, 0.1);
		z-index: 0;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			box-shadow: 0 0 5px 5px rgba($black, 0.05);
		}
	}
	&-cursor{
		position: absolute;
		left: 0;
		top: 0;
		width: 10px;
		height: 10px;
		background-color: $white;
		border-radius: 100%;
		&__orientation{
			transform-origin: left center;
			width: 0; 
			height: 0; 
			border-top: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-left: 3px solid $white;
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translate(100%, -50%);
		}
	}
}

@keyframes blinkAnim {
	0% {
		opacity: 0;
		r: 4;
	}
	25%{
		opacity: 1;
		r: 7;
	}
	50% {
		opacity: 0;
		r: 10;
	}
}
