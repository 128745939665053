.crosshair{
	position: relative;
	transform: scale(1) rotate(0);
	transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	display: flex;
	align-items: center;
	justify-content: center;
	&.hidden{
		opacity: 0;
	}
	svg{
		width: 30px;
		height: 30px;
		path{
			transform: translate(0, 0);
			transition: transform 0.3s ease-out 0.1s;
		}
	}
	&.hovering{
		transform: scale(1.25) rotate(120deg);
		svg{
			path{
				&:nth-child(1){
					transform: translate(-30px, 30px);
				}
				&:nth-child(2){
					transform: translate(0, -30px);
				}
				&:nth-child(3){
					transform: translate(30px, 30px);
				}
			}
		}
	}
	&.vr{}
}