.start-screen{
    &.hidden {
        opacity: 0;
        pointer-events: none;
    }
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .launchExperience {
        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
        &__button {
            position: absolute;
            right: 95px;
            top: 40px;
            padding: 20px 25px;
            font-size: 16px;
            
            .text {
                pointer-events: none;
                position: absolute;
                top: calc(100% + 10px);
                left: 0;
                width: 100%;
                text-align: center;
                font-family: $fontBook;
                font-size: 12px;
                color: $lightGrayBadge;
                width: 100%;
                transition: 0.3s ease-out;
            }
        }
        &__content {
            margin-top: -75px;
        }
        &__tools {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 95px 35px 95px;
            .left {
                display: flex;
                svg {
                    width: 100%;
                    height: 100%;
                }
                & > div {
                    cursor: pointer;
                    font-size: 0;
                    position: relative;
                    margin-right: 35px;
                    &.sound { width: 22px; height: 16px; }
                    &.fullscreen { width: 22px; height: 15px; }
                    &.share { width: 14px; height: 15px; }
                }
            }
            .goLanding {
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $fontBook;
                font-size: 14px;
                color: $lightGrayBadge;
                svg {
                    margin-left: 5px;
                    width: 10px;
                    height: 13px;
                }
            }
        }
        .content {
            &__logo{
                width: 90px;
                height: auto;
                margin: 0 auto 20px auto;
                @media screen and (max-width: 1400px) { width: 80px; }
            }
            &__subtitle {
                font-family: $fontBook;
                font-size: 22px;
                text-transform: uppercase;
                color: $grayBadge;
                @media screen and (max-width: 1400px) { font-size: 20px; }
            }
            &__btns{
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .btn{
                    cursor: pointer;
                    flex: 1 0 100%;
                    -webkit-appearance: none;
                    border: 1px solid $blueBadge;
                    border-radius: 2px;
                    font-weight: bold;
                    transition: color .3s ease-out;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    margin: 15px;
                    span { display: block; font-size: 12px; }
                    &:disabled {
                        cursor: no-drop;
                        opacity: 0.5;
                    }
                    &:not(:disabled):hover { color: $blueBadge; }
                }
                #standard {
                    cursor: pointer;
                    -webkit-appearance: none;
                    border: none;
                    background-color: transparent;
                    color: $lightGrayBadge;
                    font-size: 12px;
                    font-family: $fontBook;
                }
            }

        }
        .asso {
            position: absolute;
            left: 50%;
            bottom: 35px;
            width: 100%;
            transform: translateX(-50%);

            &__assos-logo {
                margin-top: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg { height: 38px; }
                a{ margin: 0 15px; }
            }
            &__text {
                font-size: 18px;
                font-family: $fontBook;
                line-height: 28px;
                color: $grayBadge;
                @media screen and (max-width: 1400px) { font-size: 16px; }
                span {
                    display: block;
                    font-size: 14px;
                    color: $lightGrayBadge;
                }
            }
        }
    }

    .qualitySelector {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 40px;
        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
        &__logo {
            width: 43px;
            height: auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__title {
            font-size: 24px;
            color: #2c2d2d;
            text-align: center;
            font-weight: bold;
            margin-bottom: 90px;
            @media screen and (max-width: 1400px) { margin-bottom: 80px; }
        }
        &__items {
            max-width: 75%;
            width: 600px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        &__headphones {}

        .item {
            position: relative;
            &.active {
                .item__title { color: $blueBadge; }
                .item__recommended { opacity: 1; }
            }
            &__title {
                cursor: pointer;
                margin-bottom: 10px;
                font-size: 24px;
                line-height: 24px;
                color: #2c2d2d;
                font-family: $fontMedium;
                transition: color 0.3s ease-out;
                &:hover { color: $blueBadge; }
            }
            &__recommended {
                width: 200%;
                opacity: 0;
                position: absolute;
                left: 50%;
                font: $fontBook;
                transform: translateX(-50%);
                font-size: 14px;
                color: rgba(#2c2d2d, 0.4);
            }
        }

        .headphones {
            display: flex;
            align-items: center;
            justify-content: center;
            &__icon {
                position: relative;
                width: 54px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background-color: rgba($blueBadge, 0.1);
                margin: 0 auto 30px auto;
                svg { width: 21px; height: 21px; fill: $blueBadge; }
            }
            &__text {
                color: $blueBadge;
                line-height: 24px;
                font-size: 18px;
            }
        }
    }

    .loading {
        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &__logo {
            width: 43px;
            height: auto;
            margin-bottom: 30px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__text {
            font-family: $fontBook;
            color: $grayBadge;
        }
    }

    .startvideo {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: black;
        &.hidden {
            pointer-events: none;
            opacity: 0;
        }
        video {
            width: 100%;
            height: 56.25vw;
        }
        .skip{
            opacity: 0;
            z-index: 1;
            padding: 20px;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            text-decoration: none;
            font-family: $fontBook;
            font-size: 14px;
            color: $blueBadge;
        }
    }
}
