.space-action{
	.space{
		padding: 7px 25px;
		text-align: center;
		border-radius: 5px;
		border: 1px solid $grayBadge;
		font-size: 10px;
		letter-spacing: 2px;
		position: relative;
		text-transform: uppercase;
		color: $grayBadge;
		transform: translateY(0);
		@media all and (max-width: 1024px) and (orientation:landscape) {
			display: none;
		}
		&.space-2{
			position: absolute;
			bottom: -5px;
			left: 0;
			z-index: -1;
			color: transparent;
			height: calc(100% + 5px);
		}
	}
	svg{
		display: none;
		width: 30px;
		height: 30px;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			display: block;
		}
	}
}

.unlocks{
	&__badge{
		position: fixed;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		pointer-events: none;
		align-items: center;
		display: none;
		padding: 20px;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			padding: 15px;
			pointer-events: all;
			cursor: pointer;
		}
		&.active{
			display: flex;
		}
		.unlock-back{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($white, 0.85);
		}
		&__content{
			padding-left: 20px;
			position: relative;
			display: flex;
			align-items: center;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				padding-left: 15px;
			}
			&.active{
				h3, & > div{
					opacity: 1;
				}
				h3{
					transition: opacity 0.5s ease-out;
					span{
						opacity: 1;
						transition: opacity 0.5s ease-out 0.5s;
					}
				}
				& > div{
					transition: opacity 0.5s ease-out 0.3s;
				}
				.unlocks__badge__content__action{
					.space:not(.space-2){
						animation: press 1s linear infinite;
					}
					.space-2{
						animation: blinkSpaceBar 1s linear infinite;
					}
					@media all and (max-width: 1024px) and (orientation:landscape) {
						.hand__border{
							animation: blink2 2s linear infinite;
						}
						.hand__body{
							animation: blink3 2s linear infinite;
						}
					}
				}
			}
			h3{
				font-size: 16px;
				opacity: 0;
				transition: opacity 0.3s ease-out;
				margin: 0;
				color: $grayBadge;
				font-weight: bold;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 11px;
				}
				span{
					font-family: $fontBook;
					font-size: 10px;
					display: block;
					opacity: 0;
					font-weight: normal;
					color: $blueBadge;
					transition: opacity 0.3s ease-out;
					text-transform: uppercase;
					margin-bottom: 4px;
					letter-spacing: 2px;
					@media all and (max-width: 1024px) and (orientation:landscape) {
						font-size: 9px;
					}
				}
			}
			&__action{
				margin-left: 20px;
				z-index: 1;
				position: relative;
				opacity: 0;
				transition: opacity 0.3s ease-out;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					margin-left: 15px;
				}
				.hand{
					width: 22px;
					height: 22px;
				}
			}
		}
	}
}

.unlock-img{
	opacity: 0;
	position: relative;
	z-index: 1;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		width: 100px;
		height: 100px;
	}
	&.active{
		.unlock-img__borders{
			div{
				transform: scale(1, 1) translateZ(0);
			}
		}
		.unlock-img__count{
			opacity: 1;
		}
		span{
			&:after{
				transform: translate(-50%, -50%) scale(1);
			}
		}
	}
	span{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		&:after{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			border-radius: 50%;
			width: 150%;
			height: 150%;
			background-color: rgba($blueBadge, 0.05);
			transform: translate(-50%, -50%) scale(0);
			transition: transform 0.5s ease-out 0.75s;
		}
	}
	&__count{
		position: absolute;
		bottom: 5px;
		color: $blueBadge;
		font-family: $fontBook;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		width: 100%;
		opacity: 0;
		transition: opacity 0.4s ease-out 1.15s;
		&:before{
			content: "";
			position: absolute;
			width: 1px;
			background-color: $blueBadge;
			height: 12px;
			bottom: 3px;
			left: 50%;
			transform: translateX(-50%) translateZ(0);
		}
		div{
			flex: 0 0 50%;
			&:first-child{
				text-align: right;
				padding-right: 5px;
				font-size: 15px;
			}
			&:last-child{
				text-align: left;
				padding-left: 5px;
				font-size: 12px;
			}
		}
	}
	&__borders{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		div{
			position: absolute;
			background-color: $blueBadge;
			&:first-child, &:nth-child(3){
				width: 1px;
				height: 100%;
				transform: scale(1, 0) translateZ(0);
			}
			&:nth-child(2), &:nth-child(4){
				width: 100%;
				height: 1px;
				transform: scale(0, 1) translateZ(0);
			}
			&:first-child{
				top: 0;
				right: 0;
				transform-origin: center top;
				transition: transform 0.2s $easeOutQuad;
			}
			&:nth-child(2){
				right: 0;
				bottom: 0;
				transform-origin: right center;
				transition: transform 0.2s $easeOutQuad 0.2s;
			}
			&:nth-child(3){
				left: 0;
				bottom: 0;
				transform-origin: center bottom;
				transition: transform 0.2s $easeOutQuad 0.4s;
			}
			&:nth-child(4){
				left: 0;
				top: 0;
				transform-origin: left center;
				transition: transform 0.15s $easeOutQuad 0.6s;
			}
		}
	}
	img{
		width: 100%;
		height: 100%;
		border-radius: 50%;
		box-shadow: 0 0 20px rgba(black, 0.2);
	}
	svg{
		width: 50%;
		height: auto;
		path{
			fill: $blueBadge;
		}
	}
	&:before{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 2px solid $blueBadge;
		opacity: 0.6;
		transform: translate(-50%, -50%) scale(0.8);
		z-index: -1;
		display: none;
	}
}

@keyframes press{
	0%{
		transform: translateY(5px);
	}
	49%{
		transform: translateY(5px);
	}
	50%{
		transform: translateY(0);
	}
	99%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(5px);
	}
}

@keyframes blinkSpaceBar{
	0% {
		opacity: 0;
	}
	49%{
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	99%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}
