.notification{
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, -105%);
	background-color: $white;
	text-align: left;
	color: $black;
	display: flex;
	padding: 7px 25px 10px 25px;
	align-items: center;
	justify-content: space-between;
	transition: transform 0.5s ease-in;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		padding: 7px 20px;
	}
	&.active{
		transform: translate(-50%, 0);
		transition: transform 0.5s ease-out;
		&:after{
			animation: gradient 5s linear infinite;
		}
	}
	&.noimg{
		.notification-text{
			margin-right: 0;
		}
		.notification-img {
			display: none;
		}
	}
	&:after{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3px;
		background: linear-gradient(to right, #ff9b5e, #ff1dbd, #23b5ff, #1636ff, #ff1dbd, #ff9b5e);
		background-size: 300% 100%;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			height: 2px;
		}
	}
	&-text{
		font-size: 12px;
		flex: 0 0 auto;
		margin-right: 15px;
		white-space: nowrap;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			font-size: 11px;
		}
	}
	&-img{
		flex: 0 0 25px;
		height: 25px;
		border-radius: 50%;
		overflow: hidden;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			flex: 0 0 20px;
			height: 20px;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
