.interlude{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	pointer-events: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: $white;
	visibility: hidden;
	&.fadeout{
		&:after{
			opacity: 1;
			transition: opacity 3s ease-out;
		}
	}
	&.active{
		visibility: visible;
		z-index: 100;
	}
	&.white{
		&:after { background-color: $white; }
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0;
		transition: opacity 3s ease-out;
	}
	&__chap{
		z-index: 1000;
		display: flex;
		text-transform: uppercase;
		font-family: $fontBook;
		font-size: 14px;
		opacity: 0;
		position: absolute;
		top: calc(50% - 110px);
		left: 50%;
		transform: translateX(-50%);
		transition: opacity 2s $easeInQuad;
		&.active{
			opacity: 0.6;
			transition: opacity 3s $easeOutQuad;
			span{
				will-change: transform;
			}
		}
	}
	&__names{
		z-index: 1000;
		font-size: 75px;
		&__name {
			display: none;
			span:not(.noblur){
				filter: blur(25px);
				transform: translateZ(0);
				opacity: 0;
				transition: filter 2s $easeInQuad;
				&.active{
					filter: blur(0px);
					transition: filter 3s $easeOutQuad;
				}
			}

			&.chap5 {
				flex-direction: column;
				.title, .subtitle {
					opacity: 0;
					position: absolute;
					left: 50%;
					top: 50%;
					width: 100%;
					transform: translate3d(-50%, -50%, 0);
					margin: auto;
				}
				.title {
					font-size: 60px;
					color: $dark;
					font-family: $font;
					text-transform: uppercase;
					font-weight: bold;
					line-height: 80%;
					@media screen and (max-width: 1400px) { font-size: 40px; }
					span {
						background: linear-gradient(to bottom right, #ff9b5e, #ff1dbd, #23b5ff, #1636ff, #ff1dbd, #ff9b5e);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-size: 300% 600%;
						animation: gradientDiag 10s linear infinite;
					}
				}
				.subtitle {
					font-size: 40px;
					color: $dark;
					font-family: $font;
					font-weight: bold;
					line-height: 80%;
					@media screen and (max-width: 1400px) { font-size: 25px; }
				}
			}
		}
	}
	&__dates{
		&__date{
			z-index: 1000;
			position: absolute;
			font-family: $fontBook;
			font-size: 28px;
			bottom: 50px;
			left: 50px;
			text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3);
			text-align: left;
			opacity: 0;
			display: none;
			b{
				font-weight: bold;
				font-family: $font;
				font-size: 40px;
			}
		}
	}
}
