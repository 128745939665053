.tutorial{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	align-items: center;
	justify-content: center;
	text-align: center;
	pointer-events: none;
	color: $grayBadge;
	display: none;
	&__back{
		background: url(../img/bg-fade.png) no-repeat center center;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: -1;
	}
	&__logo{
		position: absolute;
		top: 50px;
		left: 50%;
		opacity: 0;
		transform: translateX(-50%);
		img{
			width: auto;
			height: 55px;
		}
	}
	&__content{
		flex: 0 0 50%;
		h1, h2{
			opacity: 0;
			transform: translateY(40px);
		}
		h1{
			text-transform: uppercase;
			margin: 0;
		}
		h2{
			text-transform: uppercase;
			font-family: $fontBook;
			font-weight: normal;
			font-size: 18px;
			margin: 15px 0 0 0;
		}
		&__keys{
			display: flex;
			align-items: flex-end;
			justify-content: center;
			margin-top: 50px;
			.tutorial-key{
				flex: 0 0 33%;
				opacity: 0;
				transform: translateY(50px);
				svg{
					transform-origin: center bottom;
					animation: mouse 2s $easeOutQuad infinite;
					height: 40px;
					transform: translateY(10px);
				}
				&:nth-child(2){
					margin: 0;
					.key{
						&:nth-child(1){
							.space{
								&:not(.space-2){
									animation: pressZ 4s linear infinite, blinkArrowKeys 7s infinite;
								}
								&.space-2{
									animation: blinkZ 4s linear infinite;
								}
							}
						}
						&:nth-child(2){
							.space{
								&:not(.space-2){
									animation: pressQ 4s linear infinite, blinkArrowKeys 7s infinite;
								}
								&.space-2{
									animation: blinkQ 4s linear infinite;
								}
							}
						}
						&:nth-child(3){
							.space{
								&:not(.space-2){
									animation: pressS 4s linear infinite, blinkArrowKeys 7s infinite;
								}
								&.space-2{
									animation: blinkS 4s linear infinite;
								}
							}
						}
						&:nth-child(4){
							.space{
								&:not(.space-2){
									animation: pressD 4s linear infinite, blinkArrowKeys 7s infinite;
								}
								&.space-2{
									animation: blinkD 4s linear infinite;
								}
							}
						}
					}
				}
				.space{
					font-family: $fontBook;
					&:not(.space-2){
						animation: press 2s linear infinite;
					}
					&.space-2{
						animation: blinkSpaceBar 2s linear infinite;
					}
				}
				p{
					font-family: $fontBook;
					font-weight: normal;
					font-size: 16px;
					margin-top: 15px;
				}
			}
		}
	}
}

@keyframes pressZ{
	0%{
		transform: translateY(5px);
	}
	24%{
		transform: translateY(5px);
	}
	25%{
		transform: translateY(0);
	}
	99%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(5px);
	}
}

@keyframes blinkZ{
	0% {
		opacity: 0;
	}
	24%{
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	99%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
}

@keyframes pressQ{
	0%{
		transform: translateY(0);
	}
	24%{
		transform: translateY(0);
	}
	25%{
		transform: translateY(5px);
	}
	49%{
		transform: translateY(5px);
	}
	50%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(0);
	}
}

@keyframes blinkQ{
	0% {
		opacity: 1;
	}
	24%{
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	49%{
		opacity: 0;
	}
	50%{
		opacity: 1;
	}
	100%{
		opacity: 1;
	}
}

@keyframes pressS{
	0%{
		transform: translateY(0);
	}
	49%{
		transform: translateY(0);
	}
	50%{
		transform: translateY(5px);
	}
	74%{
		transform: translateY(5px);
	}
	75%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(0);
	}
}

@keyframes blinkS{
	0% {
		opacity: 1;
	}
	49%{
		opacity: 1;
	}
	50%{
		opacity: 0;
	}
	74%{
		opacity: 0;
	}
	75%{
		opacity: 1;
	}
	100%{
		opacity: 1;
	}
}

@keyframes pressD{
	0%{
		transform: translateY(0);
	}
	74%{
		transform: translateY(0);
	}
	75%{
		transform: translateY(5px);
	}
	99%{
		transform: translateY(5px);
	}
	100%{
		transform: translateY(0);
	}
}

@keyframes blinkD{
	0% {
		opacity: 1;
	}
	74%{
		opacity: 1;
	}
	75%{
		opacity: 0;
	}
	99%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes mouse{
	0% {
		transform: translateY(10px) rotate(-15deg);
	}
	50%{
		transform: translateY(10px) rotate(15deg);
	}
	100% {
		transform: translateY(10px) rotate(-15deg);
	}
}
