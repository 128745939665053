.game-ui{
  position: fixed;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: none;
  transition: opacity 1.5s ease-out;
  &.visible{
    display: flex;
  }
  &.hidden{
    opacity: 0;
  }
}
