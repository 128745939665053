.menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	&__back{
		background: url(../img/bg-fade.png) no-repeat center center;
		background-size: cover;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: -1;
	}
	&__head{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 50px 50px 30px 50px;
		width: 100%;
		opacity: 0;
		&__tel{
			font-size: 16px;
			.button{
				padding: 15px 15px 15px 40px;
				font-size: 14px;
				.icon-tel{
					width: 14px;
					height: 14px;
					left: 15px;
				}
				.text{
					pointer-events: none;
					position: absolute;
					top: calc(100% + 10px);
					left: 0;
					width: 100%;
					text-align: center;
					font-family: $fontBook;
					font-size: 10px;
					color: $grayBadge;
					width: 100%;
					transition: 0.3s ease-out;
				}
			}
		}
		&__logo{
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			top: 50px;
			left: 50%;
			transform: translateX(-50%);
			img{
				width: auto;
				height: 52px;
			}
		}
		&__badges{
			text-align: center;
			color: $grayBadge;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			div{
				display: flex;
				align-items: flex-end;
				margin-bottom: 5px;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					bottom: 3px;
					left: 50%;
					width: 1px;
					height: 13px;
					transform: translateX(-50%);
					background-color: $grayBadge;
				}
				span{
					margin: 0 10px;
					font-family: $fontBook;
					font-size: 12px;
					color: $grayBadge;
					&.menu-badge-number{
						font-size: 14px;
						color: $blueBadge;
					}
				}
			}
			p{
				text-transform: uppercase;
				color: $blueBadge;
				font-size: 12px;
				font-family: $fontBook;
			}
		}
		&__leave{
			position: relative;
			top: auto;
			right: auto;
			opacity: 1;
			.badges__leave__cross{
				background-color: transparent;
				width: 55px;
				height: 55px;
			}
		}
	}
	&__content{
		color: $grayBadge;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 35px;
		width: 100%;
		position: relative;
		&__chapter{
			margin-bottom: 50px;
			position: relative;
			opacity: 0;
			&__name{
				cursor: pointer;
				color: $lightGrayBadge;
				transition: color 0.5s $easeOutQuad;
				&:hover{
					color: $blueBadge !important;
				}
				&.active{
					color: $grayBadge;
				}
			}
			.menu-chapter-num{
				top: calc(100% + 10px);
				transition: opacity 0.35s $easeInQuad;
				&.active{
					opacity: 1;
					transition: opacity 0s;
				}
			}
		}
		&__tuto{
			position: absolute;
			top: 0;
			right: 50px;
			height: calc(100% - 50px);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-family: $fontBook;
			color: $grayBadge;
			font-size: 12px;
			font-weight: normal;
			opacity: 0;
			&__el{
				&:nth-child(1){
					p{
						margin-top: 2px;
					}
				}
				&:nth-child(2){
					margin: 40px 0;
				}
				svg{
					width: auto;
					height: 60px;
					transform: translateX(6px);
					path, rect{
						fill: $grayBadge;
					}
				}
				p{
					margin-top: 10px;
				}
				.keys{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					.key{
						&:first-child{
							flex: 0 0 100%;
							display: flex;
							justify-content: center;
							text-align: center;
							margin: 0 0 10px 0;
							.space-2{
								left: 50%;
								transform: translate(-50%, 0);
							}
						}
					}
				}
				.space-action{
					opacity: 1;
					margin-left: 0;
					margin-bottom: 5px;
					.space{
						font-weight: bold;
						letter-spacing: 1px;
					}
					&.key{
						margin: 0 5px;
						.space{
							padding: 7px;
							width: 30px;
							animation: blinkArrowKeys 7s infinite;
							&:after{
								content: attr(data-arrow);
								position: absolute;
								top: 50%;
								color: $grayBadge;
								font-weight: bold;
								left: 50%;
								transform: translate(-50%, -50%);
								opacity: 0;
								animation: blinkSpaceBar 7s infinite;
							}
						}
					}
					&.echap{
						width: 100%;
						display: flex;
						text-align: center;
						justify-content: center;
						.space{
							padding: 7px;
							width: 40px;
						}
						.space-2{
							left: 50%;
							transform: translate(-50%, 0);
						}
					}
				}
			}
		}
	}
	&__foot{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0 50px 30px 50px;
		width: 100%;
		opacity: 0;
		&__btns{
			display: flex;
			& > div{
				margin-right: 20px;
				cursor: pointer;
				display: flex;
				svg{
					width: 18px;
					height: 18px;
				}
			}
		}
		&__asso{
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
			&__text{
				color: $grayBadge;
				font-family: $fontBook;
				font-size: 13px;
				span{
					display: block;
					font-style: italic;
					font-size: 11px;
					color: $lightGrayBadge;
					margin-top: 4px;
				}
			}
			&__logos{
				display: flex;
				justify-content: center;
				margin-top: 10px;
				a{
					margin: 0 10px;
				}
				svg{
					width: auto;
					height: 30px;
				}
			}
		}
		&__landing{
			font-family: $fontBook;
		}
	}
}

@keyframes blinkArrowKeys{
	0% {
		color: $grayBadge;
	}
	49%{
		color: $grayBadge;
	}
	50% {
		color: transparent;
	}
	99%{
		color: transparent;
	}
	100%{
		color: $grayBadge;
	}
}
