.ambience {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
    opacity: 0;
	&.chap4 {
		background-color: rgba(10, 0, 0, 0.2);
		box-shadow: inset 0 0 350px rgba($red, 0.2);
	}
	&.chap5 {
		background-color: rgba(10, 0, 0, 0.3);
		box-shadow: inset 0 0 600px rgba(25, 0, 0, 1.0);
		& > div {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			box-shadow: inset 0 0 600px #000000;
		}
	}
}
