.profile{
	position: absolute;
	top: 40px;
	left: 40px;
	color: $black;
	display: flex;
	align-items: center;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		top: 15px;
		left: 15px;
	}
	&__rank{
		position: relative;
		opacity: 0.8;
		.hexagon{
			position: relative;
			width: 70px; 
			height: 40.41px;
			background-color: $white;
			margin: 20.21px 0;
			box-shadow: 0 0 20px rgba($black, 0.25);
			z-index: 2;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				width: 50px;
				height: 28.87px;
				margin: 14.43px 0;
				box-shadow: 0 0 10px rgba($black, 0.25);
			}
			&:before, &:after{
				content: "";
				position: absolute;
				z-index: 1;
				width: 49.5px;
				height: 49.5px;
				transform: scaleY(0.5774) rotate(-45deg);
				background-color: inherit;
				left: 10.2513px;
				box-shadow: 0 0 20px rgba($black, 0.25);
				@media all and (max-width: 1024px) and (orientation:landscape) {
					width: 35.36px;
					height: 35.36px;
					left: 7.3223px;
					box-shadow: 0 0 10px rgba($black, 0.25);
				}
			}
			&:before{
				top: -24.7496px;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					top: -17.6777px;
				}
			}
			&:after{
				bottom: -24.7496px;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					bottom: -17.6777px;
				}
			}
			span{
				display: block;
				position: absolute;
				top: 0px;
				left: 0;
				width: 70px;
				height: 40.4145px;
				z-index: 2;
				background: inherit;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					width: 50px;
					height: 28.8675px;
				}
			}
			&--white{
				transition: transform 0.5s ease-in-out;
				transform: scale(1);
				&.big{
					transform: scale(1.1);
				}
			}
			&--pulse{
				position: absolute;
				top: 50%;
				left: 50%;
				box-shadow: none;
				transform: translate(-50%, calc(-50% - 20.21px));
				z-index: 1;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					transform: translate(-50%, calc(-50% - 14.43px));
				}
				&.pulse{
					animation: pulse2 2.5s linear;
				}
				&:before, &:after{
					box-shadow: none;
				}
			}
		}
		.hexagon-content{
			position: absolute;
			width: 70px;
			height: 81px;
			top: 0;
			left: 0;
			z-index: 3;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;
			font-size: 13px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				width: 50px;
				height: 58px;
				font-size: 12px;
			}
			.profile-rank{
				position: relative;
				margin-top: 0;
				font-size: 26px;
				background: linear-gradient(to bottom right, #ff9b5e, #ff1dbd, #23b5ff, #1636ff, #ff1dbd, #ff9b5e);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 300% 600%;
				animation: gradientDiag 10s linear infinite;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 18px;
				}
			}
			p, .profile-rank{
				z-index: 3;
			}
			p{
				font-size: 0.8em;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 0.7em;
					margin-top: 4px;
					margin-bottom: 2px;
				}
			}
			& > div{
				overflow: hidden;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					line-height: 1;
				}
			}
		}
	}
	&__data{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 5px 5px 20px;
		left: 0;
		position: relative;
		min-width: 175px;
		border-radius: 0 100px 100px 0;
		background-color: rgba($white, 0.2);
		opacity: 0.8;
		transform: translateX(-5px);
		@media all and (max-width: 1024px) and (orientation:landscape) {
			min-width: 135px;
			padding: 3px 3px 3px 20px;
			transform: translateX(-10px);
		}
		&__text{
			display: flex;
			flex-direction: column;
			margin-right: 15px;
			.profile-name{
				font-size: 0.8em;
				color: $white;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 0.55em;
					line-height: 1;
				}
			}
			.profile-type{
				font-family: $fontBook;
				color: rgba($white, 0.7);
				font-size: 8px;
				transition: opacity 0.4s ease-out;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 10px;
					line-height: 1;
					margin-top: 2px;
				}
				&.hidden{
					opacity: 0;
				}
			}
		}
		.profile-img{
			width: 30px;
			height: 30px;
			overflow: hidden;
			border-radius: 50%;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				width: 25px;
				height: 25px;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&__progress{
			position: absolute;
			top: calc(100% + 5px);
			left: 20px;
			width: calc(100% - 20px - 25px - 5px);
			height: 3px;
			transform: translateZ(0) translateY(100%) scaleY(1);
			background-color: $white;
			border-radius: 5px;
			overflow: hidden;
			box-shadow: 0 0 20px 0 rgba($black, 0.25);
			transition: transform 0.6s ease-out;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				top: calc(100% + 3px);
				width: calc(100% - 20px - 23px - 5px);
				box-shadow: 0 0 10px 0 rgba($black, 0.25);
			}
			&.big{
				transform: translateZ(0) translateY(100%) scaleY(2);
			}
			span{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform-origin: left center;
				transform: translateZ(0) scaleX(0.8);
				background: linear-gradient(to right, #ff9b5e, #ff1dbd, #23b5ff, #1636ff, #ff1dbd, #ff9b5e);
				background-size: 300% 100%;
				transition: transform 0.5s ease-out;
				animation: gradient 15s linear infinite;
			}
		}
		&__indic{
			position: absolute;
			right: 25px;
			bottom: -18px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				bottom: -13px;
			}
			&:before, &:after{
				position: absolute;
				top: 100%;
				left: 100%;
			}
			&:before{
				content: "";
				width: 0; 
				height: 0; 
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-bottom: 4px solid $white2;
				transform: translate(-10px, 50%);
				@media all and (max-width: 1024px) and (orientation:landscape) {
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
					border-bottom: 3px solid $white2;
				}
			}
			&:after{
				content: attr(data-next-rank);
				color: $white;
				font-size: 12px;
				transform: translate(-10px, 50%);
				@media all and (max-width: 1024px) and (orientation:landscape) {
					font-size: 10px;
				}
			}
		}
		&__points{
			position: absolute;
			background: linear-gradient(to bottom right, #ff1dbd, #23b5ff, #1636ff);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 300% 600%;
			font-size: 22px;
			opacity: 0;
			top: 150%;
			left: 20px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				font-size: 14px;
				top: 145%;
			}
			&.goingDown{
				background: linear-gradient(to bottom right, #ff9b5e, #ff1dbd, #23b5ff, #1636ff);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-size: 300% 600%;
			}
		}
	}
	&__messages{
		padding: 5px 16px;
		background: linear-gradient(to right, #23b5ff, #1636ff, #23b5ff);
		background-size: 300% 100%;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: $white;
		margin-left: 50px;
		opacity: 0;
		transform: scale(0.75);
		transition: opacity 0.25s ease-out, transform 0.25s ease-out;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			padding: 8px 12px 4px 12px;
			margin-left: 15px;
		}
		&.active{
			opacity: 1;
			transform: scale(1);
			animation: gradient 10s linear infinite;
			& > div{
				&:after{
					visibility: visible;
					animation: pulse3 3s linear infinite;
				}
			}
		}
		&.numerous{
			background: linear-gradient(to right, #152bff, #8226ff, #152bff);
			background-size: 300% 100%;
			animation: gradient 8s linear infinite;
			& > div{
				&:after{
					animation: pulse3 1.75s linear infinite;
				}
			}
		}
		&.enormous{
			background: linear-gradient(to right, #8226ff, #ff0072, #8226ff);
			background-size: 300% 100%;
			animation: gradient 6s linear infinite;
			& > div{
				&:after{
					animation: pulse3 1s linear infinite;
				}
			}
		}
		&.huge{
			background: linear-gradient(to right, #fe6d55, #ff0072, #fe6d55);
			background-size: 300% 100%;
			animation: gradient 4s linear infinite;
			& > div{
				&:after{
					animation: pulse3 0.75s linear infinite;
				}
			}
		}
		& > div, .profile-messages{
			margin-right: 10px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				margin-right: 8px;
				font-size: 0.6em;
				line-height: 1;
			}
		}
		& > div{
			position: relative;
			&:after{
				content: "!";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				visibility: hidden;
				transform: translate(-50%, -50%);
			}
		}
		&__text{
			font-family: $fontBook;
			font-size: 12px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				font-size: 9px;
				line-height: 1;
			}
		}
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 150% 50%;
	}
}

@keyframes gradientDiag {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 100%;
	}
}


@keyframes pulse2 {
	0% {
		opacity: 1;
		transform: translate(-50%, calc(-50% - 20.21px)) scale(0.8);
	}
	25%{
		opacity: 0;
	}
	30% {
		opacity: 0;
		transform: translate(-50%, calc(-50% - 20.21px)) scale(1.6);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, calc(-50% - 20.21px)) scale(0.8);
	}
}

@keyframes pulse3 {
	0% {
		opacity: 1;
		transform: translate(-50%, -50%) scaleX(1) scaleY(1);
	}
	45%{
		opacity: 0;
	}
	50% {
		opacity: 0;
		transform: translate(-50%, -50%) scaleX(3) scaleY(2);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scaleX(1) scaleY(1);
	}
}
