.mobile-ui{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    &.active{
        pointer-events: all;
    }
    .nipple, .interact{
        width: 100%;
        position: relative;
    }
    .nipple{
        height: 100%;
    }
    .interact{
        height: 65%;
        z-index: 2;
        pointer-events: none;
        &.hovering{
            pointer-events: all;
        }
    }
}