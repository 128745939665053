.actions{
	position: absolute;
	top: 50%;
	left: 50%;
	&.active{
		.actions__slot{
			span{
				opacity: 0.1;
				transition: opacity 0.8s ease-out;
			}
		}
	}
	&__slot{
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		color: white;
		font-weight: bold;
		text-shadow: 2px 2px 1px rgba($black, 0.5),
			-1px -1px 0 rgba($black, 0.65),  
			1px -1px 0 rgba($black, 0.65),
			-1px 1px 0 rgba($black, 0.65),
			1px 1px 0 rgba($black, 0.65);
		& > div{
			color: currentColor;
			text-align: center;
			width: 25px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 0 auto;
			opacity: 0;
			transition: opacity 0.2s ease-out;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				width: 20px;
				height: 30px;
			}
			svg{
				width: 100%;
				height: 100%;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					display: none;
				}
				&.hand{
					display: none;
					@media all and (max-width: 1024px) and (orientation:landscape) {
						display: block;
					}
				}
			}
		}
		.action-slot{
			white-space: nowrap;
			opacity: 0;
			position: absolute;
			transition: opacity 0.4s ease-out, transform 0.4s ease-out;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				font-size: 0.7em;
			}
			&.active{
				opacity: 1;
				transform: translate(0, 0) !important;
				& + div{
					opacity: 1;
					transition: opacity 0.8s ease-out;
					svg{
						&.mouse{
							path{
								animation: blink 1s linear infinite;
							}
						}
						&.hand{
							.hand__border{
								animation: blink2 1s linear infinite;
							}
							.hand__body{
								animation: blink3 1s linear infinite;
							}
						}
					}
				}
			}
		}
		.action-info{
			opacity: 0;
			width: 200px;
			max-width: 90%;
			transition: opacity 0.4s ease-out, transform 0.4s ease-out;
			&.active{
				opacity: 1;
				transform: translate(0, 0) !important;
				& + div{
					opacity: 1;
					transition: opacity 0.8s ease-out;
				}
			}
		}
		&:first-child{
			text-align: center;
			flex-direction: column;
			left: 50%;
			transform: translateX(-50%);
			top: -75px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				top: -50px;
			}
			.action-slot{
				top: -30px;
				transform: translate(0, -20px);
				@media all and (max-width: 1024px) and (orientation:landscape) {
					top: -20px;
				}
			}
		}
	}
}

@keyframes blink{
	0% {
		opacity: 0;
	}
	49%{
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes blink2{
	0% {
		opacity: 1;
	}
	49%{
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100%{
		opacity: 0.5;
	}
}

@keyframes blink3{
	0% {
		opacity: 0.5;
	}
	49%{
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100%{
		opacity: 1;
	}
}
