@font-face {
    font-family: 'ITC Avant Garde Gothic Std Demi';
    src: url('../../../static/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'),
        url('../../../static/fonts/ITCAvantGardeStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Demi';
    src: url('../../../static/fonts/ITCAvantGardeStd-Demi.woff2') format('woff2'),
        url('../../../static/fonts/ITCAvantGardeStd-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Book';
    src: url('../../../static/fonts/ITCAvantGardeStd-Bk.woff2') format('woff2'),
        url('../../../static/fonts/ITCAvantGardeStd-Bk.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Medium Condensed';
    src: url('../../../static/fonts/ITCAvantGardeStd-MdCn.woff2') format('woff2'),
        url('../../../static/fonts/ITCAvantGardeStd-MdCn.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

/* COLORS */
$colorOrange: #ffa300;
$blue: #0056ff;
$red: rgba(255, 0, 0, 0.6);
$white: #ffffff;
$white2: #f6f6f6;
$gray: #929292;
$blueLight: #ebf1f8;
$black: #3c3c3c;
$dark: #282828;
$yellow: #fff58d;
$grayBadge: #373b40;
$lightGrayBadge: #aaadb0;
$blueBadge: #1665fe;

/* FONTS */
$font: 'ITC Avant Garde Gothic Std Demi', sans-serif;
$fontBook: 'ITC Avant Garde Gothic Std Book', sans-serif;
$fontMedium: 'ITC Avant Garde Gothic Std Medium Condensed', sans-serif;

$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInQuad : cubic-bezier(0.55, 0.085, 0.68, 0.53);
