.subtitles, .objective{
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	line-height: 1.5;
	font-size: 20px;
	text-shadow: 2px 2px 1px rgba($black, 0.5),
     -1px -1px 0 rgba($black, 0.65),  
      1px -1px 0 rgba($black, 0.65),
      -1px 1px 0 rgba($black, 0.65),
       1px 1px 0 rgba($black, 0.65);
	@media all and (max-width: 1024px) and (orientation:landscape) {
		font-size: 16px;
	}
}

.subtitles{
	width: 50%;
	bottom: 55px;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		bottom: 50px;
		width: 60%;
	}
}

.objective{
	color: $yellow;
	bottom: 10px;
	width: auto;
	padding: 10px 35px;
	white-space: nowrap;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		bottom: 5px;
	}
	.goal{
		opacity: 0;
	}
	.goal-disk{
		position: absolute;
		background-color: $yellow;
		border-radius: 50%;
		width: 15px;
		height: 15px;
		top: 50%;
		left: 0;
		opacity: 0;
		transform: translateY(-50%) scale(0);
		box-shadow: 0 0 5px 5px rgba($yellow, 0.25);
		&:after{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 2px solid $yellow;
			opacity: 0.6;
			transform: translate(-50%, -50%) scale(0.8);
		}
		&.active{
			&:after{
				animation: pulse 4s ease-out infinite;
			}
		}
	}
}

@keyframes pulse {
	0% {
		opacity: 0.6;
		transform: translate(-50%, -50%) scale(0.8);
	}
	25%{
		opacity: 0;
	}
	30% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(2.2);
	}
	100% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(0.8);
	}
}
