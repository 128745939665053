.badges{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: stretch;
	z-index: 1000;
	display: none;
	@media all and (max-width: 1024px) and (orientation:landscape) {
		padding: 25px;
	}
	&.active{
		.badges__back{
			opacity: 0.8;
		}
		.badges__leave{
			opacity: 1;
		}
	}
	&__back{
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		overflow: hidden;
		cursor: pointer;
		background: url(../img/background.jpg) no-repeat center center;
		background-size: cover;
		opacity: 0;
		transition: transform 0.5s ease-out, opacity 0.5s ease-out;
		@media all and (max-width: 1024px) and (orientation:landscape) {
		}
	}
	&__leave{
		position: absolute;
		top: 50px;
		right: 60px;
		opacity: 0;
		z-index: 10;
		cursor: pointer;
		display: flex;
		align-items: center;
		color: $grayBadge;
		font-family: $fontBook;
		font-size: 15px;
		transition: opacity 0.5s ease-out, transform 0.5s ease-out;
		@media all and (max-width: 1024px) and (orientation:landscape) {
			left: 20px;
			top: 20px;
		}
		&:hover{
			.badges__leave__cross{
				&:before{
					transform: translate(-50%, -50%) scale(0);
				}	
				&:after{
					transform: translate(-50%, -50%) scale(1);
				}
				div{
					transform: translate(-50%, -50%) translateZ(0) scale(0.8);
				}
			}
		}
		&__cross{
			width: 65px;
			height: 65px;
			background-color: $white;
			box-shadow: 0 0 20px rgba($white, 0.25);
			transform: scale(0.8);
			border-radius: 50%;
			position: relative;
			margin-left: 10px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
				width: 50px;
				height: 50px;
			}
			&:before, &:after{
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				transition: transform 0.5s $easeOutQuad;
			}
			&:before{
				border: 1px solid $lightGrayBadge;
				transform: translate(-50%, -50%) scale(1);
			}
			&:after{
				background-color: rgba($lightGrayBadge, 0.2);
				transform: translate(-50%, -50%) scale(0);
			}
			div{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				border-radius: 50%;
				transform: translate(-50%, -50%) translateZ(0) scale(1);
				transition: transform 0.2s $easeOutQuad 0.1s;
				z-index: 1;
				&:before, &:after{
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 25px;
					height: 1px;
					border-radius: 5px;
					background-color: $grayBadge;
					z-index: 1;
					@media all and (max-width: 1024px) and (orientation:landscape) {
						width: 20px;
					}
				}
				&:before{
					transform: translate(-50%, -50%) rotate(45deg);
				}
				&:after{
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}
	}
	&__badge{
		position: relative;
		display: none;
		flex: 0 0 50%;
		background-color: $white;
		color: $grayBadge;
		flex-direction: column;
		&__head{
			padding: 50px 60px 0 75px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 50px;
			@media all and (max-width: 1024px) and (orientation:landscape) {

			}
			&__img{
				position: relative;
				width: 100px;
				height: 100px;
				align-self: center;
				margin-right: 25px;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					width: 100px;
					height: 100px;
				}
				.unlock-img{
					width: 100%;
					height: 100%;
					opacity: 1;
				}
			}
			&__name{
				p{
					font-family: $fontBook;
					text-transform: uppercase;
					color: $blueBadge;
					letter-spacing: 2px;
					font-size: 13px;
					margin-bottom: 5px;
					@media all and (max-width: 1024px) and (orientation:landscape) {
					}
				}
				h2{
					margin: 0;
					color: $grayBadge;
					font-size: 20px;
					@media all and (max-width: 1024px) and (orientation:landscape) {
						font-size: 16px;
						margin-bottom: 3px;
					}
				}
			}
		}
		&__content{
			font-family: $fontBook;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 60px 0 75px;
			@media all and (max-width: 1024px) and (orientation:landscape) {
			}
			p{
				opacity: 0;
				color: $lightGrayBadge;
				font-size: 15px;
				margin-bottom: 15px;
				width: 75%;
				@media all and (max-width: 1024px) and (orientation:landscape) {
					margin-bottom: 8px;
				}
				&:last-child{
					margin-bottom: 0;
				}
				b{
					color: $grayBadge;
				}
			}
		}
		&__foot{
			margin-top: auto;
			padding: 30px 60px 40px 75px;
			background-color: rgba(0, 0, 0, 0.04);
			font-size: 14px;
			font-family: $fontBook;
			position: relative;
			&__title{
				position: relative;
				&:after{
					content: "";
					position: absolute;
					top: 0;
					right: 0;
					background: url(../img/logo-bw.png) no-repeat center center;
					width: 40px;
					height: 40px;
				}
			}
			.small{
				font-size: 12px;
				margin-top: 5px;
				margin-bottom: 20px;
				color: $lightGrayBadge;
			}
			&__logos{
				display: flex;
				align-items: center;
				a{
					margin-right: 20px;
					color: $lightGrayBadge;
					text-decoration: none;
					font-size: 12px;
					position: relative;
					&:last-child{
						margin-left: auto;
						padding-right: 10px;
						margin-right: 5px;
						transition: color 0.4s $easeOutQuad;
						&:before, &:after{
							content: "";
							position: absolute;
							top: 50%;
							right: 0;
							background-color: $grayBadge;
							height: 1px;
							width: 6px;
							transition: transform 0.4s $easeOutQuad;
						}
						&:before{
							transform: rotate(25deg) translateY(-1.5px);
						}
						&:after{
							transform: rotate(-25deg) translateY(1.5px);
						}
						&:hover{
							color: $grayBadge;
							&:before{
								transform: translateX(5px) rotate(25deg) translateY(-1.5px);
							}
							&:after{
								transform: translateX(5px) rotate(-25deg) translateY(1.5px);
							}
						}
					}
				}
				img{
					width: auto;
					height: 30px;
				}
			}
		}
	}
}
