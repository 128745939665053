.info {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate3d(-50%, 40px, 0);
    &-text {
        font-size: 14px;
        font-family: $fontBook;
        font-weight: normal;
        width: 250px;
        max-width: 100%;
        opacity: 0;
        transform: translate(0, 20px);
        text-shadow: 1px 1px 1px rgba($black, 0.5),
            -1px -1px 0 rgba($black, 0.65),  
            1px -1px 0 rgba($black, 0.65),
            -1px 1px 0 rgba($black, 0.65),
            1px 1px 0 rgba($black, 0.65);
        transition: opacity 0.4s ease-out, transform 0.4s ease-out;
        &.active {
            transform: translate(0);
            opacity: 1;
        }
    }
}