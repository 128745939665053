.button {
    cursor: pointer;
    position: relative;
    font-family: $fontBook;
    text-decoration: none;
    display: inline-block;

    &.icon-left { padding-left: 50px; }
    &.icon-right { padding-right: 100px; }   

    &--bluelight {
        background-color: rgba($blueLight, 0.8);
        color: $blue;
        padding: 25px 20px;
    }
    .icon-facebook {
        position: absolute;
        top: 50%;
        left: 20px;
        width: 15px;
        height: 17px;
        transform: translateY(-50%);
        svg {
            fill: $blueBadge;
            width: 100%;
            height: 100%;
        }
    }

    .icon-tel {
        background-color: rgba($white, 0.1);
        position: absolute;
        top: 50%;
        left: 20px;
        width: 18px;
        height: 18px;
        transform-origin: center;
        font-size: 0;
        transform: translateY(-50%);
        svg {
            fill: $blueBadge;
            width: 100%;
            height: 100%;
        }
    }
  }
  