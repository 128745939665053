.vignette {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	box-shadow: inset 0 0 100px $black;
	&.blue { 
		box-shadow: inset 0 0 100px $blue;
		// background: radial-gradient(transparent, transparent, $blue);
	}
	&.red {
		box-shadow: inset 0 0 100px $red;
		// background: radial-gradient(transparent, transparent, $red);
	}
}
